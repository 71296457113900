import { Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import KIcon from "../components/iconifier";
import { useAPI } from "../controllers/ContextProvider";
import { Outlet, useNavigate } from "react-router-dom";
import { ButtonAppBar } from "../components/hb";
import { FullScreenNav, KNavigation } from "../components/sidebar";
import { FormBuilder } from "../panels/forms/formbuilder";
import { KLoader, Loading } from "../components/loader";
export default function ManagementWindow(props) {
  let { get, post, token, appConfig, setAppConfig, setLoaderStyle, loaderStyle } = useAPI();
  let nav = useNavigate();
  const [navs, snavs] = React.useState(
    appConfig !== null ? appConfig.sidebar : []
  );
  React.useEffect(() => {
    get("appconfig/get/app/1")
      .then((r) => {
        setAppConfig(r.data);
        snavs(r.data.sidebar);
        if (appConfig.sidebar.length > 0) {
          nav(appConfig.sidebar[0].link, { replace: true });
        }
      })
      .then((r) => {
        Loading.hideLoader("large", loaderStyle);
        setLoaderStyle("window");
      });
  }, []);
  return (
    <div>
      <KNavigation nav={nav} navs={navs} type="sidebar">
        <ButtonAppBar />
        <div
          style={{
            overflowX: "hidden",
            height: window.innerHeight - 82,
            padding: 10,
          }}
        >
          <KLoader size="window" />
          <FormBuilder />
          <Outlet />
        </div>
      </KNavigation>
    </div>
  );
}

import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import KTable from '../components/table';
export default function KTabPanel({ tabheads, tabcomps }) {
    return (
        <Tabs aria-label="Basic tabs" defaultValue={0}>
            <TabList>
                {tabheads.map((prop) => (

                    <Tab>{prop}</Tab>
                ))}
            </TabList>
            {tabcomps}
        </Tabs>
    );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Back, MenuFold } from '@icon-park/react';
import { useNavigate } from 'react-router';
import { useAPI } from '../controllers/ContextProvider';
import {closeNav, openNav} from "./sidebar";
import { Card } from '@mui/joy';
import { Stack } from '@mui/material';

export function ButtonAppBar() {
  let nav = useNavigate()
  let {setToken, app, setNav, navIsOpen} = useAPI()
  
  return (
    <Card style={{
      borderRadius:0,
      height: 57,
      padding: 2
    }}>
      {/* <AppBar position="static"> */}
        {/* <Toolbar> */}
        <Stack direction={"row"}>

          <span style={{width:50}}></span>
          <IconButton
            size="large"
            edge="start"
            onClick={()=>{navIsOpen?closeNav():openNav(); setNav(!navIsOpen)}}
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            >
            <MenuFold />
          </IconButton>
          <IconButton
            onClick={()=>{ nav(-1)}}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            >
            <Back />
          </IconButton>
        
          <Button onClick={()=>{setToken(null);nav('/',{replace:true})}} color="inherit">Log out</Button>
        {/* </Toolbar> */}
      {/* </AppBar> */}
            </Stack>
    </Card>
  );
}
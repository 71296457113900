import * as React from "react";
import {useLocation} from "react-router";
import {Grid} from "@mui/material";
import { Alert, Card, CardContent, Stack, Typography } from '@mui/joy'
import {useAPI} from "../../controllers/ContextProvider";
import {Button} from "@mui/joy";
import {
    MapData,
    TextData,
    FileData,
    ImageData,
    ListTextData,
    TabularInput,
    GetPreview
} from '../../components/inputpreview'
import KTabPanel from "../tabpanel";
import FormTab from "./formtab";
import TabPanel from "@mui/joy/TabPanel";

export default  function FeedbackPreviewer() {
    let data = useLocation().state;
    const [topicData, setData] = React.useState();
    const [tabheads,setTabHeads] = React.useState([]);
    const [tabBodies, setTabBodies] = React.useState([]);
    let {get,post,setFormId,setShowForm,setAdditionalFormData} = useAPI();
    React.useEffect(()=>{
        console.log(data.data)
        let p = [data.data.topic.topic.name];
        let tp = [<TabPanel value={0}><ParentFeedback data={data.data}/></TabPanel>];
        let i =1;
        data.data.topic.children.forEach(t=>{
            p.push(t.name);
            tp.push(
                <TabPanel value={i}>
                    <FormTab child={true} form={t.page} parent={data.data.tag}/>
                </TabPanel>)
            i++
        });
        setTabBodies(tp)
        setTabHeads(p);
    },[]);
    return(
        <div>
            <KTabPanel tabheads={tabheads} tabcomps={tabBodies}/>
        </div>
    )
}
function ParentFeedback({data}){
    let {get,post,setFormId,setShowForm,setAdditionalFormData} = useAPI();
    return (
        <div>
            <h4></h4>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}></Grid>
                <Grid style={{textAlign:"center"}} item xs={12} md={4}>
                    <h3>{data.topic.topic.name}</h3>
                </Grid>
                <Grid item xs={12} md={12}>
                </Grid>
                {
                    data.topic.children.map((prop,key)=>(
                        <Grid item xs={4} md={2} key={key}>
                            <Button onClick={()=>{setFormId(prop.page);
                                setAdditionalFormData({parent_tag:data.tag})
                                setShowForm(true);}}>Add {prop.name}</Button>
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={12}>
                </Grid>
                {
                    data.contents.map((prop,key)=>(
                        <Grid item xs={prop.rform.styles.xs} md={prop.rform.styles.md} lg={prop.rform.styles.lg}>
                            <Card style={{backgroundColor:"#e4e4e4"}}>
                                <h4>{prop.rform.name}</h4>
                                <GetPreview name={prop.rform.name} data={prop.objectresponse} type={prop.rform.type.name}/>
                            </Card>
                        </Grid>
                    ))
                }

            </Grid>
        </div>
    )
}